import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { setStrankConfig } from '@veraio/strank';
import { ThemeProvider } from '@emotion/react';
import { setAuthConfig, AUTH_EVENTS_TYPES } from '@oneecosystem/authenticate';
import axios from 'axios';
import BaseApp from './screens/App';
import Logout from './screens/Public/Logout';
import RedirectCallback from './screens/Public/RedirectCallback';
import { AuthProvider } from './components/UI';
import { trackGeoLocation } from './services/profiles';
import initGlobalStyles from './styles/globalCSS';
import theme from './styles/theme.json';

setAuthConfig({
  identity: { domain: identityProvider.keycloak, clientId: 'OneLife' },
  oneLifeDomain: apiUrls.oneLife,
  [AUTH_EVENTS_TYPES.SET_TOKEN]: (token) => {
    axios.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
  },
  [AUTH_EVENTS_TYPES.LOGIN]: trackGeoLocation,
  [AUTH_EVENTS_TYPES.LOGIN_WITH_PASSWORD]: () => {
    trackGeoLocation();
    window.location = '/dashboard';
  },
  [AUTH_EVENTS_TYPES.RENEW_SESSION]: trackGeoLocation,
  [AUTH_EVENTS_TYPES.LOGOUT]: () => {
    window.location = '/';
  },
});

setStrankConfig({ parseHtml: true, environment: strankEnvironment, storage: window.localStorage });

const App = () => (
  <Router>
    <ThemeProvider theme={theme}>
      <AuthProvider renderLogout={Logout} renderRedirect={RedirectCallback}>
        {initGlobalStyles()}
        <BaseApp />
      </AuthProvider>
    </ThemeProvider>
  </Router>
);

const container = document.getElementById('one-academy-administration-root');
const root = createRoot(container);
root.render(<App />);
