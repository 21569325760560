import { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Paper, Modal } from '../../../../UI';
import { container, header, modalWrap, bodyWrap } from './styles';
import QuestionsAddMore from '../QuestionsAddMore';

const QuestionsAddModal = forwardRef((props, ref) => {
  const { onQuestionsDataUpdate } = props;
  const [show, setShow] = useState(false);
  const [quiz, setQuiz] = useState();

  useImperativeHandle(ref, () => ({
    setIsShow: () => setShow(!show),
    setQuizData: (data) => setQuiz(data),
  }));

  const handleClose = () => {
    setShow(!show);
  };

  return (
    <Modal css={modalWrap} show={show} withoutClose>
      <Paper
        header={
          <header css={header}>
            <h3>Add questions</h3>
            <Icon onClick={handleClose} material iconName="close" />
          </header>
        }
        css={container}>
        <div css={bodyWrap}>
          <QuestionsAddMore quizData={quiz} onModalClose={handleClose} onQuestionsDataUpdate={onQuestionsDataUpdate} />
        </div>
      </Paper>
    </Modal>
  );
});

QuestionsAddModal.propTypes = {
  onQuestionsDataUpdate: PropTypes.func,
};

export default QuestionsAddModal;
