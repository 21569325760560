import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { omit } from 'lodash-es';
import {
  Paper,
  Form,
  FormInput,
  FormParagraph,
  CheckBoxForm,
  Breadcrumbs,
  showSuccess,
  showApiError,
} from '../../../components/UI';
import BackButton from '../../../components/Shared/BackButton';
import { postNewCategory, getCategoryById, updateCategory } from '../../../services/categories';
import { pages } from './config';
import { wrapper, backContainer, paperContain } from './styles';

const AddEditCategory = () => {
  const history = useHistory();
  const { categoryId } = useParams();
  const [categoryData, setCategoryData] = useState({});

  useEffect(() => {
    categoryId && fetchCategoryData();
  }, [categoryId]);

  const fetchCategoryData = async () => {
    const [res, err] = await getCategoryById(categoryId);
    err ? showApiError(err) : setCategoryData(res);
  };

  const handleSubmit = async (formData) => {
    const values = { ...categoryData, ...formData };
    const body = omit(values, ['translations', 'id']);

    const [, error] = await (categoryId ? updateCategory(categoryId, body) : postNewCategory(body));
    if (error) return showApiError(error);

    showSuccess({
      title: `${categoryId ? 'Edited' : 'Create'} category`,
      message: `Successfully ${categoryId ? 'edited' : 'create'} category.`,
    });

    history.push('/categories');
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={pages(categoryId)} />
      <BackButton css={backContainer} title={categoryId ? 'Edit Category' : 'Add Category'} />
      <Paper css={paperContain}>
        <Form
          onSubmit={handleSubmit}
          submitButton={{ children: categoryId ? 'Edit Category' : 'Add Category' }}
          values={categoryData}>
          <FormInput id="name" label="Category name (Strank key)" required />
          <FormInput id="iconUrl" label="Icon URL" required />
          <FormParagraph>
            <CheckBoxForm id="visible" label="Visible" />
          </FormParagraph>
        </Form>
      </Paper>
    </div>
  );
};

export default AddEditCategory;
