import { postReq, getReq, putReq } from './axios/makeRequest';
import {
  getAllParams,
  editQuizBody,
  editQuestionBody,
  editAnswersBody,
  addAnswersBulkBody,
  addQuestionsBulkBody,
} from './models/quizzes';

const baseUrl = `${apiUrls.oneAcademy}/quizzes`;
const baseUrlQuestions = `${apiUrls.oneAcademy}/quizQuestions`;
const baseUrlAnswers = `${apiUrls.oneAcademy}/quizAnswers`;

// Quizzes

export const postNewQuiz = (values) => postReq(`${baseUrl}/new-with-questions`, values);

export const getAllQuizzes = (options = {}) => {
  const params = getAllParams(options);
  return getReq(`${baseUrl}/list?${params}`);
};

export const updateQuiz = (id, data) => putReq(`${baseUrl}/${id}/update`, editQuizBody(data));

// Questions

export const postNewQuestions = (id, data) => postReq(`${baseUrlQuestions}/new-bulk`, addQuestionsBulkBody(id, data));

export const getAllQuestions = (options = {}) => {
  const params = getAllParams(options);
  return getReq(`${baseUrlQuestions}/list?${params}`);
};

export const updateQuestion = (id, data) => putReq(`${baseUrlQuestions}/${id}/update`, editQuestionBody(data));

// Answers

export const postNewAnswers = (id, data) => postReq(`${baseUrlAnswers}/new-bulk`, addAnswersBulkBody(id, data));

export const getAllAnswers = (options = {}) => {
  const params = getAllParams(options);
  return getReq(`${baseUrlAnswers}/list?${params}`);
};

export const updateAnswer = (id, data) => putReq(`${baseUrlAnswers}/${id}/update`, editAnswersBody(data));
