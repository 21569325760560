import { omit, pick, isNumber } from 'lodash-es';
import queryString from 'query-string';
import badge from '../../assets/images/default-badge.jpeg';

export const editAddCoursesBody = (body) => ({
  name: body?.name,
  introText: body?.introText,
  summary: body?.summary,
  picEncodedData: body?.picEncodedData,
  thumbPicEncodedData: body?.thumbPicEncodedData,
  status: body?.status,
  description: body?.description,
  categoryId: body?.category?.id,
  topics: body?.topics,
  ...((body?.afterCourseId || isNumber(body?.afterCourseId)) && {
    afterCourseId: body?.afterCourseId,
  }),
});

export const coursePackageModel = (el) => ({
  catalogueItemId: el?.catalogueItemId,
  tokensPercentage: el?.tokensPercentage ?? 0,
});

export const updateCoursePackagesModel = (body) => ({
  catalogueItems: body?.map(coursePackageModel) ?? [],
});

export const editCourseStepBody = (body) => ({
  title: body?.title,
  visible: body?.visible,
  afterStepId: body?.afterStepId,
});

export const getCourseModel = (response) =>
  response && {
    ...response,
    picUrl: response.pictureUrl ?? badge,
    expert: {
      ...omit(response.expert, ['firstName', 'lastName']),
      name: `${response?.expert?.firstName} ${response?.expert?.lastName}`,
    },
  };

export const getAllCoursesParams = (options) => {
  const defaultSort = {
    sortBy: 'id',
    sortDirection: 'desc',
  };

  const mapSort = (sort) => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  const filterFields = [];
  const filterValues = [];

  if (options.status) {
    filterFields.push('status');
    filterValues.push(options.status);
  }

  if (options.categoryId) {
    filterFields.push('categoryId');
    filterValues.push(options.categoryId);
  }

  if (options.searchCriteria && options.searchInput?.length) {
    filterFields.push(options.searchCriteria);
    filterValues.push(options.searchInput);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};
