import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Table } from '../../../components/UI';
import TableSearchBarCategories from '../../../components/Categories/TableSearchBarCategories';
import { columns } from './config';
import { wrapper, container, navLink } from './styles';
import { getAllCategories } from '../../../services/categories';

const Categories = () => {
  const { getText } = useTranslations();

  const fetchAllCategories = () => async (tableOptions) => {
    const options = {
      ...tableOptions,
    };
    const res = await getAllCategories(options);
    return res;
  };

  return (
    <div css={wrapper}>
      <div css={container}>
        <h1>Categories List</h1>
        <Link to="/categories/add-category" css={navLink}>
          Create Category
        </Link>
      </div>
      <Table
        getDataKey="data"
        getDataMethod={fetchAllCategories()}
        emptyMessage="There are no categories."
        columns={columns(getText)}
        filterBar={TableSearchBarCategories}
        pageSize={10}
      />
    </div>
  );
};

export default Categories;
