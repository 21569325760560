import { Route } from 'react-router-dom';
import AddEditCategory from './Categories/AddEditCategory';
import AddCourse from './Courses/AddCourse';
import OrderCourses from './Courses/OrderCourses';
import CourseEditDetails from './Courses/CourseEditDetails';
import OrderSteps from './Courses/OrderSteps';
import PackageDetails from './Packages/PackageDetails';
import AddEditBadge from './Badges/Badges/AddEditBadge';
import AddEditCondition from './Badges/Conditions/AddEditCondition';
import AcademyAccountDetails from './AcademyAccounts/AcademyAccountDetails';

export const PrivateRoutes = ({ isAdmin }) =>
  [
    isAdmin && {
      path: '/categories/add-category',
      component: AddEditCategory,
    },
    isAdmin && {
      path: '/categories/:categoryId',
      component: AddEditCategory,
    },
    isAdmin && {
      path: '/courses/add-course',
      component: AddCourse,
    },
    isAdmin && {
      path: '/courses/order-courses',
      component: OrderCourses,
    },
    isAdmin && {
      path: '/courses/:courseId/order-steps',
      component: OrderSteps,
    },
    isAdmin && {
      path: '/courses/:courseId/package',
      component: PackageDetails,
    },
    isAdmin && {
      path: '/courses/:courseId',
      component: CourseEditDetails,
    },
    isAdmin && {
      path: '/badges/badge/add-badge',
      component: AddEditBadge,
    },
    isAdmin && {
      path: '/badges/badge/:badgeId',
      component: AddEditBadge,
    },
    isAdmin && {
      path: '/badges/condition/add-condition',
      component: AddEditCondition,
    },
    isAdmin && {
      path: '/badges/condition/:conditionId',
      component: AddEditCondition,
    },
    isAdmin && {
      path: '/academy-accounts/:accountId/achievements',
      component: AcademyAccountDetails,
    },
    isAdmin && {
      path: '/academy-accounts/:accountId/courses',
      component: AcademyAccountDetails,
    },
    isAdmin && {
      path: '/academy-accounts/:accountId/quiz-takes',
      component: AcademyAccountDetails,
    },
    isAdmin && {
      path: '/academy-accounts/:accountId',
      component: AcademyAccountDetails,
    },
    isAdmin && {
      path: '/packages/:packageId',
      component: PackageDetails,
    },
  ]
    .filter(Boolean)
    .map((route) => <Route key={route.path} {...route} />);
