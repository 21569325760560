import { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import {
  Row,
  Col,
  Paper,
  Button,
  Icon,
  Tooltip,
  Form,
  FormInput,
  FormParagraph,
  CheckBoxForm,
  showError,
} from '../../../../UI';
import { rowComp, colComp } from '../../../../UI/Grid';
import { QuestionsAddForm } from '../../Questions';
import { AnswersAddForm } from '../../Answers';
import {
  quizPaperHeader,
  quizCreateForm,
  formBtnsCreateQuizWrap,
  formBtnsCreateQuizCancel,
  quizInfoWrap,
  quizInfoInner,
  questionCollapseWrap,
  questionCollapseHeader,
  questionCollapseArrow,
  questionCollapseBody,
  addQuestionButton,
  answersListWrap,
  answersListItem,
  uploadQuizButton,
} from './styles';

const QuizAdd = (props) => {
  const { data, onSubmit } = props;

  const [editQuiz, setEditQuiz] = useState(!data);
  const [quiz, setQuiz] = useState(data ?? {});

  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(0);

  const SubmitBarCreateQuiz = (submitProps) => (
    <div css={formBtnsCreateQuizWrap}>
      <Button onClick={submitProps.onSubmit}>Save</Button>
      <Button
        css={formBtnsCreateQuizCancel}
        secondary
        disabled={quiz && isEmpty(quiz)}
        onClick={() => setEditQuiz(false)}>
        Cancel
      </Button>
    </div>
  );

  const onSubmitQuiz = (formData) => {
    setQuiz((prev) => ({
      ...prev,
      ...formData,
      visible: formData?.visible ?? quiz?.visible ?? false,
    }));
    setEditQuiz(false);
  };

  const handleQuestionSubmit = (formData) => {
    const updatedQuestions = quiz?.questions.map((question, index) =>
      index === selectedQuestion ? { ...question, ...formData } : question,
    );

    setQuiz((prev) => ({
      ...prev,
      questions: updatedQuestions,
    }));
  };

  const handleAddQuestion = () => {
    quiz?.questions && expandQuestion(quiz?.questions?.length);

    setQuiz((prev) => ({
      ...prev,
      questions: prev?.questions ? [...(prev?.questions ?? []), {}] : [{}],
    }));
  };

  const handleQuestionRemove = (questionIndex) => {
    const newData = [...(quiz?.questions ?? [])];
    newData?.splice(questionIndex, 1);
    setQuiz((prev) => ({
      ...prev,
      questions: newData,
    }));
  };

  const expandQuestion = (questionIndex) => {
    setSelectedAnswer(0);
    setSelectedQuestion(questionIndex);
  };

  const handleAddAnswer = (questionIndex) => {
    const newData = { ...quiz };
    const question = { ...newData.questions[questionIndex] };

    if (question.answers) question.answers.push({});
    else question.answers = [{}];

    newData.questions[questionIndex] = question;

    setQuiz(newData);
    question?.answers && setSelectedAnswer(question?.answers.length - 1);
  };

  const handleAnswerSubmit = (formData, questionIndex) => {
    const newData = { ...quiz };
    const question = { ...newData.questions[questionIndex] };
    const answer = { ...question.answers[selectedAnswer], ...formData };

    question.answers[selectedAnswer] = answer;

    setQuiz(newData);
  };

  const handleAnswerRemove = (questionIndex) => {
    const newData = { ...quiz };
    const question = { ...newData.questions[questionIndex] };
    question.answers.splice(selectedAnswer, 1);

    setQuiz(newData);
  };

  const handleQuizUpload = async () => {
    const newData = {
      ...quiz,
      questions: quiz.questions
        .filter((el) => !isEmpty(el))
        .map((el) => ({
          ...el,
          answersType: el?.answers?.filter((value) => value.isCorrect && value.visible).length > 1 ? 'multi' : 'single',
          answers: el?.answers?.filter((value) => !isEmpty(value)),
        })),
    };

    const hasEmptyAnswers = newData?.questions.some((el) => isEmpty(el.answers));

    if (!newData?.questions.length || hasEmptyAnswers)
      return showError('Quiz invalid check for questions with no answers!');

    const hasInvalidAnswers = newData?.questions.every((el) =>
      el.answers.some((item) => item.isCorrect && item.visible),
    );

    if (!hasInvalidAnswers) return showError('Quiz invalid check for questions with no correct answers!');

    const response = await onSubmit(newData);

    setQuiz(response === 'success' ? {} : newData);
    response === 'success' && setEditQuiz(true);
  };

  return (
    <Paper header="Course quizes">
      <Paper
        header={
          <div css={quizPaperHeader}>
            {editQuiz ? (
              <Form css={quizCreateForm} onSubmit={onSubmitQuiz} renderSubmit={SubmitBarCreateQuiz} values={quiz ?? {}}>
                <FormParagraph css={rowComp({ margin: '0 -16px' })}>
                  <FormParagraph css={colComp({ sm: 6, horizontalGap: 16 })}>
                    <FormInput required id="title" label="Title (Strank key)" />
                  </FormParagraph>
                  <FormParagraph css={colComp({ sm: 6, horizontalGap: 16, verticalGap: 34 })}>
                    <CheckBoxForm id="visible" label="Visible" />
                  </FormParagraph>
                </FormParagraph>
              </Form>
            ) : (
              <div css={quizInfoWrap}>
                <div css={quizInfoInner}>
                  <Tooltip content={quiz?.visible ? 'visible' : 'not-visible'}>
                    <Icon
                      material
                      color={quiz?.visible ? 'info' : 'gray'}
                      iconName={quiz?.visible ? 'visibility' : 'visibility_off'}
                    />
                  </Tooltip>
                  <h3>{quiz?.title}</h3>
                </div>
                {!editQuiz && <Button onClick={() => setEditQuiz(true)}>Edit</Button>}
              </div>
            )}
          </div>
        }>
        {quiz && !isEmpty(quiz) && (
          <>
            {quiz.questions?.map((question, questionIndex) => (
              <div key={questionIndex} css={questionCollapseWrap(questionIndex === selectedQuestion)}>
                <div css={questionCollapseHeader} onClick={() => expandQuestion(questionIndex)} role="presentation">
                  <Row align="center">
                    <Col flex="0 1 auto" dFlex align="center">
                      <div css={questionCollapseArrow(questionIndex === selectedQuestion)}>
                        <Icon
                          onClick={() => expandQuestion(questionIndex)}
                          material
                          color="gray"
                          iconName={questionIndex === selectedQuestion ? 'expand_less' : 'expand_more'}
                        />
                      </div>
                    </Col>
                    <Col flex="1 0 0px " dFlex align="center">
                      <QuestionsAddForm
                        onSubmit={handleQuestionSubmit}
                        data={question ?? {}}
                        onRemove={() => handleQuestionRemove(questionIndex)}
                      />
                    </Col>
                  </Row>
                </div>
                {questionIndex === selectedQuestion && !isEmpty(question) && (
                  <div css={questionCollapseBody}>
                    <Row horizontalGap={5}>
                      <Col flex="0 1 auto" dFlex>
                        <div css={answersListWrap}>
                          {question?.answers?.map((answer, answerIndex) => (
                            <div
                              key={answerIndex}
                              css={answersListItem(answerIndex === selectedAnswer)}
                              role="presentation"
                              onClick={() => setSelectedAnswer(answerIndex)}>
                              <Tooltip content={answer?.visible ? 'visible' : 'not-visible'}>
                                <Icon
                                  material
                                  color={answer?.visible ? 'info' : 'gray'}
                                  iconName={answer?.visible ? 'visibility' : 'visibility_off'}
                                />
                              </Tooltip>
                              <Tooltip content={answer?.isCorrect ? 'correct' : 'not-correct'}>
                                <Icon
                                  material
                                  color={answer?.isCorrect ? 'green' : 'red'}
                                  iconName={answer?.isCorrect ? 'task_alt' : 'highlight_off'}
                                />
                              </Tooltip>
                              <p>{answer.text}</p>
                            </div>
                          ))}
                          <Button
                            onClick={() => handleAddAnswer(questionIndex)}
                            disabled={
                              question?.answers &&
                              question?.answers.length > 0 &&
                              isEmpty(question?.answers[question?.answers?.length - 1])
                            }>
                            + Add Answer
                          </Button>
                        </div>
                      </Col>
                      <Col flex="1 0 0px " dFlex>
                        {question?.answers && !isEmpty(question?.answers) && (
                          <AnswersAddForm
                            onRemove={() => handleAnswerRemove(questionIndex)}
                            data={question?.answers[selectedAnswer] ?? {}}
                            onSubmit={(formData) => handleAnswerSubmit(formData, questionIndex)}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            ))}
            <div css={addQuestionButton}>
              <Button
                onClick={handleAddQuestion}
                large
                disabled={
                  quiz?.questions && quiz?.questions.length > 0 && isEmpty(quiz?.questions[quiz?.questions?.length - 1])
                }>
                + Add Question
              </Button>
            </div>
          </>
        )}
      </Paper>
      <div css={uploadQuizButton}>
        <Button secondary onClick={handleQuizUpload} disabled={!quiz?.questions && isEmpty(quiz?.questions)} large>
          Upload Quiz
        </Button>
      </div>
    </Paper>
  );
};

QuizAdd.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default QuizAdd;
