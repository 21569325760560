import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Table, Row, Col } from '../../../components/UI';
import TableFilterBarCourses from '../../../components/Courses/TableFilterBarCourses';
import { getAllCourses } from '../../../services/courses';
import { columns } from './config';
import { wrapper, container, navLink } from './styles';

const Courses = () => {
  const { getText } = useTranslations();

  const fetchAllCourses = () => async (tableOptions) => {
    const options = {
      ...tableOptions,
    };
    const res = await getAllCourses(options);
    return res;
  };

  return (
    <div css={wrapper}>
      <div css={container}>
        <h1>Courses List</h1>
        <Row container noWrap gap={8}>
          <Col flex="0 1 auto">
            <Link to="/courses/order-courses" css={navLink}>
              Order Courses
            </Link>
          </Col>
          <Col flex="0 1 auto">
            <Link to="/courses/add-course" css={navLink}>
              Create Course
            </Link>
          </Col>
        </Row>
      </div>

      <Table
        getDataKey="data"
        getDataMethod={fetchAllCourses()}
        emptyMessage="There are no courses."
        columns={columns(getText)}
        filterBar={TableFilterBarCourses}
        pageSize={10}
      />
    </div>
  );
};

export default Courses;
