import { postReq, getReq, putReq } from './axios/makeRequest';
import {
  editAddCoursesBody,
  updateCoursePackagesModel,
  getCourseModel,
  getAllCoursesParams,
  editCourseStepBody,
} from './models/courses';

const baseUrl = `${apiUrls.oneAcademy}/courses`;
const baseUrlSteps = `${apiUrls.oneAcademy}/course-steps`;

export const postNewCourse = (values) => postReq(`${baseUrl}/new`, editAddCoursesBody(values));

export const getCourseById = async (id) => {
  const [response, error] = await getReq(`${baseUrl}/${id}`);
  return [getCourseModel(response), error];
};

export const updateCourse = (id, data) => putReq(`${baseUrl}/${id}/update`, editAddCoursesBody(data));

export const updateCoursePackages = (id, data) =>
  putReq(`${baseUrl}/${id}/catalogue-items/update`, updateCoursePackagesModel(data));

export const getAllCourses = (options = {}) => {
  const params = getAllCoursesParams(options);
  return getReq(`${baseUrl}/list?${params}`);
};

export const updateCourseSteps = (id, data) => putReq(`${baseUrlSteps}/${id}/update`, editCourseStepBody(data));
