export const wrap = (theme) => ({
  position: 'relative',
  width: '100%',
  padding: 10,
  borderLeft: `1px solid ${theme.borderLight}`,
});

export const formBtns = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const answerHeader = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 10,
  marginBottom: 10,
  borderBottom: `1px solid ${theme.borderLight}`,
});

export const answerDataLabel = {
  width: 120,
  ' & > h4': {
    lineHeight: 1.5,
  },
};

export const answerBody = {
  paddingTop: 16,
  paddingBottom: 16,
};

export const answerDataItem = (theme) => ({
  paddingTop: 5,
  paddingBottom: 5,
  borderBottom: `1px solid ${theme.borderLight}`,
});

export const deleteButton = {
  ' & > i': {
    margin: 0,
  },
};
