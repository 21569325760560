import { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { pages } from './config';
import { Row, Col, Button, Breadcrumbs, showApiError, showSuccess } from '../../../components/UI';
import BackButton from '../../../components/Shared/BackButton';
import CourseInfo from '../../../components/Courses/Course';
import CourseInfoBox from '../../../components/Courses/Course/CourseInfoBox';
import CoursePackages from '../../../components/Courses/Packages';
import Materials from '../../../components/Courses/Materials';
import AddLessons from '../../../components/Courses/Lessons';
import Quizzes from '../../../components/Courses/Quizzes';
import { CourseTopicsList } from '../../../components/Courses/Topics';
import { getCourseById, updateCourse } from '../../../services/courses';
import { wrapper, backContainer, courseInfoContain, statusChangeWrap } from './styles';
import defaultImage from '../../../assets/images/default-badge.jpeg';

const CourseEditDetails = () => {
  const { courseId } = useParams();
  const [courseData, setCourseData] = useState();
  const courseInfoBoxRef = useRef(null);

  useEffect(() => {
    courseId && fetchCourseData();
  }, [courseId]);

  const fetchCourseData = async () => {
    const [res, err] = await getCourseById(courseId);

    if (err) return showApiError(err);

    setCourseData(res);
  };

  const handleChange = async (formData) => {
    const newCourseData = { ...courseData, ...formData };

    if (formData?.picUrl?.picture || formData?.picUrl?.picture === '')
      newCourseData.picEncodedData = formData.picUrl.picture;

    if (formData?.picUrl?.picThumbnail || formData?.picUrl?.picThumbnail === '')
      newCourseData.thumbPicEncodedData = formData.picUrl.picThumbnail;

    const [, error] = await updateCourse(courseId, newCourseData);
    if (error) return showApiError(error);

    showSuccess({ title: 'Edited course', message: 'Successfully edited course.' });
    setCourseData({
      ...newCourseData,
      packages: formData?.catalogueItems ?? courseData?.catalogueItems ?? [],
      picUrl: newCourseData.picEncodedData
        ? newCourseData.picEncodedData
        : newCourseData.picEncodedData === ''
        ? defaultImage
        : courseData?.picUrl,
    });
    !formData?.status && courseInfoBoxRef.current.setIsEdit();
  };

  const handleTopicsChange = async (newTopics) => {
    const newCourseData = { ...courseData, topics: newTopics };

    const [, error] = await updateCourse(courseId, newCourseData);
    if (error) return showApiError(error);

    showSuccess({ title: 'Edited course', message: 'Successfully added topic.' });
    setCourseData((prev) => ({ ...prev, topics: newTopics }));
  };

  const changeCourseStatus = () => {
    const { status } = courseData;
    handleChange({ status: status === 'active' ? 'inactive' : 'active' });
  };

  return (
    <>
      <div css={wrapper}>
        <Breadcrumbs items={pages(courseId)} />
        <BackButton css={backContainer} title="Course Details" />
        <CourseInfo course={courseData} css={courseInfoContain} />
        <Row gap={16}>
          <Col xl={6} md={12}>
            <CourseInfoBox ref={courseInfoBoxRef} data={courseData} onCourseInfoChange={handleChange} />
            <AddLessons />
          </Col>
          <Col xl={6} md={12}>
            <CoursePackages />
            <Materials />
            <Quizzes />
            <CourseTopicsList topics={courseData?.topics} onChange={handleTopicsChange} />
          </Col>
        </Row>
      </div>
      {courseData && (
        <div css={statusChangeWrap}>
          <Row horizontalGap={8} justify="flex-end">
            <Col flex="0 1 auto" dFlex>
              <Link to={`/courses/${courseId}/order-steps`}>
                <Button large secondary>
                  Order Steps
                </Button>
              </Link>
            </Col>
            <Col flex="0 1 auto" dFlex>
              <Button large onClick={changeCourseStatus}>
                {courseData?.status === 'active' ? 'Deactivate' : 'Activate'}
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default CourseEditDetails;
