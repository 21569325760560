export const wrap = (theme) => ({
  border: `1px solid ${theme.borderLight}`,
  padding: '8px 16px',
  marginBottom: 16,
});

export const questionInfoTitle = {
  display: 'flex',
  alignItems: 'center',
  '& > h4': {
    marginRight: 16,
    marginLeft: 16,
  },
};

export const formBtns = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const actionsButtonsWrap = {
  marginLeft: -4,
  marginRight: -4,
};

export const iconButton = {
  marginLeft: 4,
  marginRight: 4,
  ' & > i': {
    margin: 0,
  },
};

export const loadMoreButton = {
  marginTop: 16,
  '& > div[role=button]': {
    width: '100%',
    justifyContent: 'center',
  },
};
