import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslations } from '@veraio/strank';
import { isNil, isString } from 'lodash-es';
import { Skeleton } from '../../UI';
import ColorBatch from '../../Shared/ColorBatch';
import { courseStatus } from '../../../screens/Private/Courses/config';
import { courseInfo, courseData, infoContainer, marginBottom4 } from './styles';

const CourseInfo = (props) => {
  const { course, className } = props;
  const { getText } = useTranslations();

  return isNil(course) ? (
    <section {...(isString(className) && { className })}>
      <article css={courseData}>
        <section>
          <Skeleton width={250} height={25} css={marginBottom4} />
          <Skeleton width={320} height={20} />
          <Skeleton width={320} height={20} />
          <Skeleton width={320} height={20} />
          <Skeleton width={320} height={20} />
          <Skeleton width={320} height={20} />
          <Skeleton width={320} height={20} />
        </section>
      </article>
    </section>
  ) : (
    <section {...(isString(className) && { className })}>
      <article css={courseData}>
        <section>
          <h2 css={courseInfo}>{getText(course?.name)}</h2>
          {course?.status && (
            <ColorBatch type={courseStatus[course.status]?.color}>{courseStatus[course.status]?.text}</ColorBatch>
          )}
          <p css={infoContainer}>
            Course ID:<span>{course?.id}</span>
          </p>
          <p css={infoContainer}>
            Course Order:<span>{course?.courseOrder}</span>
          </p>
          <p css={infoContainer}>
            Created By:<span>{course?.createdBy}</span>
          </p>
          <p css={infoContainer}>
            Updated By:<span>{course?.updatedBy}</span>
          </p>
          <p css={infoContainer}>
            Created On:
            <span>{moment(course?.createdOn).format('DD.MM.YYYY, HH:mm:ss')}</span>
          </p>
          <p css={infoContainer}>
            Updated On:
            <span>{moment(course?.updatedOn).format('DD.MM.YYYY, HH:mm:ss')}</span>
          </p>
        </section>
      </article>
    </section>
  );
};

CourseInfo.propTypes = {
  course: PropTypes.object,
  className: PropTypes.string,
};

export default CourseInfo;
