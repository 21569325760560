export const topicsListContainer = {
  marginBottom: 32,

  '& .topics-list': {
    padding: 0,

    '& .topics-list-item': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1.5em',
      listStyle: 'none',

      '& .topics-list-item-name': {
        flex: 1,
        marginRight: '1em',
      },

      '& .course-topic-button': {
        padding: 6,

        '&.edit': {
          marginRight: '1em',
        },

        '& > i': {
          marginRight: 0,
        },
      },
    },
  },

  '& .topics-list-empty': {
    marginBottom: '2em',
  },
};
