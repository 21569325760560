import { keyframes } from '@emotion/react';

// Input styles start (base one)
export const inputContainer = (isHorizontal) => ({
  display: 'flex',
  flexDirection: isHorizontal ? 'row' : 'column',
  alignItems: 'flex-start',
  justifyContent: isHorizontal ? 'space-between' : 'flex-start',
  position: 'relative',
  width: '100%',
  height: isHorizontal ? '48px' : '115px',
  outline: 'none',
});

export const inputLabel = (isHorizontal) => (theme) => ({
  fontWeight: 600,
  opacity: 1,
  color: theme.textLightSecondary,
  margin: `
      ${isHorizontal ? '12px' : 0}
      ${isHorizontal ? '24px' : 0}
      ${isHorizontal ? 0 : '8px'}
      0`,
});

export const inputField = (hasError, hasText) => (theme) =>
  [
    {
      fontWeight: 400,
      lineHeight: 'normal',
    },
    hasError
      ? {
          border: `1px solid ${theme.error}`,
        }
      : hasText
      ? {
          border: `1px solid ${theme.success}`,
          borderRadius: 3,
        }
      : {},
  ];

const helpTextAnimation = keyframes`
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 24px;
    opacity: 1;
  }
`;

export const inputHelpContainer = (hasError) => (theme) => ({
  fontSize: '13px',
  lineHeight: 1.54,
  color: hasError ? theme.errorDark : theme.successDark,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: 6,
  fontWeight: 400,
  animation: `${helpTextAnimation} 400ms ease-out`,
});

export const inputHelpIcon = (hasError) => (theme) => ({
  padding: hasError ? 4 : 6,
  borderRadius: '50%',
  marginRight: 6,
  backgroundColor: hasError ? theme.errorLight : theme.successLight,
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& > img': {
    width: '100%',
    height: 'auto',
    maxHeight: '100%',
  },
});

export const videoPickerContainer = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
};
