export const wrap = (theme) => ({
  border: `1px solid ${theme.borderLight}`,
  backgroundColor: theme.borderVeryLight,
  marginBottom: 16,
  padding: 16,
});

export const answerWrap = (theme) => ({
  border: `1px solid ${theme.borderLight}`,
  padding: '8px 16px',
  marginBottom: 16,
  backgroundColor: theme.white,
});

export const formBtns = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const answerHeader = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 10,
  marginBottom: 10,
  borderBottom: `1px solid ${theme.borderLight}`,
});

export const answerBody = {
  paddingTop: 16,
  paddingBottom: 16,
};

export const answerDataItem = (theme) => ({
  paddingTop: 5,
  paddingBottom: 5,
  borderBottom: `1px solid ${theme.borderLight}`,
});

export const answerDataLabel = {
  width: 120,
  ' & > h4': {
    lineHeight: 1.5,
  },
};

export const addAnswersButton = {
  width: '100%',
  justifyContent: 'center',
  marginBottom: 16,
  marginTop: 8,
};
