import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import {
  Row,
  Col,
  showApiError,
  showSuccess,
  Form,
  FormInput,
  Button,
  FormParagraph,
  Tooltip,
  CheckBoxForm,
  Icon,
} from '../../../../UI';
import { rowComp, colComp } from '../../../../UI/Grid';
import AnswersAddModal from '../AnswersAddModal';
import { getAllAnswers, updateAnswer, updateQuestion } from '../../../../../services/quizzes';
import {
  wrap,
  answerWrap,
  answerHeader,
  answerDataLabel,
  answerBody,
  answerDataItem,
  formBtns,
  addAnswersButton,
} from './styles';

const AnswersList = (props) => {
  const { questionData, onQuestionDataUpdate, onAnswersDataUpdate } = props;
  const [answers, setAnswers] = useState([]);
  const [selected, setSelected] = useState(null);
  const { getText } = useTranslations();

  const answersModalRef = useRef(null);

  useEffect(() => {
    fetchAllAnswers();
  }, [questionData?.id]);

  const fetchAllAnswers = async () => {
    const [res, err] = await getAllAnswers({
      searchCriteria: 'questionId',
      searchInput: String(questionData?.id),
    });

    if (err) return showApiError(err);

    setAnswers(res?.data ?? []);
  };

  const handleSubmit = async (formData, answersData) => {
    const data = { ...answersData, ...formData };

    const updatedAnswers = answers.map((el) => (el.id === answersData.id ? { ...el, ...data } : el));

    const correctAnswers = updatedAnswers.filter((el) => el.isCorrect && el.visible);

    if (questionData.answersType === 'multi' && correctAnswers.length === 1) {
      const updateAnswerError = await editAnswer(data, answersData);
      if (updateAnswerError) return showApiError(updateAnswerError);

      const updateQuestionError = await updateQuestionType('single');
      if (updateQuestionError) return showApiError(updateQuestionError);
    } else if (questionData.answersType === 'single' && correctAnswers.length > 1) {
      const updateQuestionError = await updateQuestionType('multi');
      if (updateQuestionError) return showApiError(updateQuestionError);

      const updateAnswerError = await editAnswer(data, answersData);
      if (updateAnswerError) return showApiError(updateAnswerError);
    } else {
      const updateAnswerError = await editAnswer(data, answersData);
      if (updateAnswerError) return showApiError(updateAnswerError);
    }

    showSuccess({
      title: 'Edited answer',
      message: 'Successfully edited answer.',
    });

    setAnswers(updatedAnswers);
    setSelected(null);
  };

  const editAnswer = async (data, answersData) => {
    const [, error] = await updateAnswer(answersData.id, data);
    if (error) return error;
  };

  const updateQuestionType = async (type) => {
    const data = {
      ...questionData,
      answersType: type,
    };

    const [, error] = await updateQuestion(questionData.id, data);
    if (error) return error;

    onQuestionDataUpdate(data);
  };

  const SubmitBar = ({ onSubmit }) => (
    <div css={formBtns}>
      <Button onClick={onSubmit}>Save</Button>
      <Button secondary onClick={() => setSelected(null)}>
        Cancel
      </Button>
    </div>
  );

  const openAnswersModal = () => {
    answersModalRef.current.setIsShow();
  };

  return (
    <>
      <div css={wrap}>
        {answers.map((el) => (
          <div key={el.id} css={answerWrap}>
            {selected === el.id ? (
              <Form
                renderSubmit={SubmitBar}
                onSubmit={(formData) => handleSubmit(formData, el)}
                submitButton={{ children: 'Save' }}
                values={el}>
                <FormInput required id="text" label="Text (Strank key)" placeholder="Text" />
                <FormParagraph css={rowComp({ margin: '0 -16px' })}>
                  <FormParagraph
                    css={colComp({
                      xl: 12,
                      lg: 12,
                      horizontalGap: 16,
                    })}>
                    <CheckBoxForm id="visible" label="Visible" />
                  </FormParagraph>
                  <FormParagraph
                    css={colComp({
                      xl: 12,
                      lg: 12,
                      horizontalGap: 16,
                    })}>
                    <CheckBoxForm id="isCorrect" label="IsCorrect" />
                  </FormParagraph>
                </FormParagraph>
              </Form>
            ) : (
              <div>
                <div css={answerHeader}>
                  <div>
                    <Tooltip content={el?.visible ? 'visible' : 'not-visible'}>
                      <Icon
                        material
                        color={el?.visible ? 'info' : 'gray'}
                        iconName={el?.visible ? 'visibility' : 'visibility_off'}
                      />
                    </Tooltip>
                    <Tooltip content={el?.isCorrect ? 'correct' : 'not-correct'}>
                      <Icon
                        material
                        color={el?.isCorrect ? 'green' : 'red'}
                        iconName={el?.isCorrect ? 'task_alt' : 'highlight_off'}
                      />
                    </Tooltip>
                  </div>
                  <div>
                    <Row horizontalGap={2}>
                      <Col flex="0 1 auto" dFlex>
                        <Button onClick={() => setSelected(el.id)}>Edit</Button>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div css={answerBody}>
                  <div css={answerDataItem}>
                    <Row horizontalGap={5}>
                      <Col flex="0 1 auto" dFlex>
                        <div css={answerDataLabel}>
                          <h4>Text:</h4>
                        </div>
                      </Col>
                      <Col flex="1 0 0px " dFlex>
                        <p>{getText(el?.text)}</p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
        <Button large css={addAnswersButton} secondary onClick={openAnswersModal}>
          Add Answers
        </Button>
      </div>
      <AnswersAddModal
        ref={answersModalRef}
        answersData={answers}
        questionData={questionData}
        onQuestionDataUpdate={onQuestionDataUpdate}
        onAnswersDataUpdate={onAnswersDataUpdate}
      />
    </>
  );
};

AnswersList.propTypes = {
  questionData: PropTypes.object,
  onQuestionDataUpdate: PropTypes.func,
  onAnswersDataUpdate: PropTypes.func,
};

export default AnswersList;
