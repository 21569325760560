import { postReq, getReq, putReq } from './axios/makeRequest';
import {
  addLessonBody,
  getAllLessonsParams,
  editLessonsBody,
  editLessonsTranslationBody,
  addEditTextLessonBody,
  addEditTextLessonTranslationBody,
} from './models/lessons';

const baseUrl = `${apiUrls.oneAcademy}/lessons`;

export const postNewLesson = (values) => postReq(`${baseUrl}/new`, addLessonBody(values));

export const postNewTextLesson = (values) => postReq(`${baseUrl}/new-text`, addEditTextLessonBody(values));

export const postNewLessonTranslation = (id, data) => postReq(`${baseUrl}/${id}/translations/new`, data);

export const postNewLessonTextTranslation = (id, data) =>
  postReq(`${baseUrl}/${id}/translations/new-upload`, addEditTextLessonTranslationBody(data));

export const getLessonByIdTranslation = async (id, languageCode) => {
  const [data, err] = await getReq(`${baseUrl}/${id}/translations/${languageCode}`);

  return [err ? {} : { ...data, title: data?.step?.title }, err];
};

export const updateLesson = (id, data) => putReq(`${baseUrl}/${id}/update`, editLessonsBody(data));

export const updateTextLesson = (id, data) => putReq(`${baseUrl}/${id}/update-text`, addEditTextLessonBody(data));

export const updateLessonTranslations = (id, languageCode, data) =>
  putReq(`${baseUrl}/${id}/translations/${languageCode}/update`, editLessonsTranslationBody(data));

export const updateLessonTextTranslations = (id, languageCode, data) =>
  putReq(`${baseUrl}/${id}/translations/${languageCode}/update-upload`, addEditTextLessonTranslationBody(data, true));

export const getAllLessons = (options = {}) => getReq(`${baseUrl}/list?${getAllLessonsParams(options)}`);
