import { postReq, getReq, putReq } from './axios/makeRequest';
import { getAllCategoriesParams } from './models/categories';

const baseUrl = `${apiUrls.oneAcademy}/categories`;

export const postNewCategory = (data) => postReq(`${baseUrl}/new`, data);

export const getCategoryById = async (id) => getReq(`${baseUrl}/${id}`);

export const updateCategory = (id, data) => putReq(`${baseUrl}/${id}/update`, data);

export const getAllCategories = (options = {}) => {
  const params = getAllCategoriesParams(options);
  return getReq(`${baseUrl}/list?${params}`);
};
