import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Dropdown } from '../../UI';
import { wrapper } from './styles';

const LanguageSelect = forwardRef((props, ref) => {
  const { fullData, onChange } = props;
  const { allEnvLanguages } = useTranslations();
  const { languageCode } = useParams();

  const dropdownRef = useRef(null);

  useImperativeHandle(ref, () => ({
    resetSelect: () => dropdownRef.current.clear(),
  }));

  return (
    <div css={wrapper}>
      <Dropdown
        ref={dropdownRef}
        noClear
        placeholder="Translations"
        options={allEnvLanguages}
        displayKey="name"
        uniqueKey="code"
        onChange={(val) => onChange(fullData ? val : val.code)}
        value={allEnvLanguages?.find((el) => el.code === languageCode)}
      />
    </div>
  );
});

LanguageSelect.propTypes = {
  fullData: PropTypes.bool,
  onChange: PropTypes.func,
};

export default LanguageSelect;
