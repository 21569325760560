import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Paper } from 'components/UI';
import { CourseTopicAddEdit } from '../TopicAddEdit';
import { topicsListContainer } from './styles';

export const CourseTopicsList = (props) => {
  const { topics, onChange } = props;
  const { getText } = useTranslations();
  const [isEdit, setIsEdit] = useState(false);
  const editTopicRef = useRef();

  const handleAddTopic = (topic) => {
    const newTopics = editTopicRef.current
      ? topics.map((el, i) => (i === editTopicRef.current ? topic : el))
      : [...(topics ?? []), topic];
    onChange(newTopics);
    handleSwitchToPreviewMode();
  };

  const handleEditTopic = (topicIndex) => {
    editTopicRef.current = topicIndex;
    handleSwitchToEditMode();
  };

  const handleRemoveTopic = (topicIndex) => onChange(topics.filter((_, i) => i !== topicIndex));

  const handleSwitchToEditMode = () => setIsEdit(true);

  const handleSwitchToPreviewMode = () => {
    editTopicRef.current = null;
    setIsEdit(false);
  };

  return (
    <Paper header="Course topics" css={topicsListContainer}>
      {topics?.length ? (
        <ul className="topics-list">
          {topics.map((el, index) => (
            <li key={`${el}-${index}`} className="topics-list-item">
              <h4 className="topics-list-item-name">{getText(el)}</h4>
              <div>
                <Button
                  leftIcon={{ material: true, color: 'white', iconName: 'edit', size: 20 }}
                  className="course-topic-button edit"
                  onClick={() => handleEditTopic(index)}
                />
                <Button
                  outline
                  leftIcon={{ material: true, color: 'red', iconName: 'delete', size: 20 }}
                  className="course-topic-button"
                  onClick={() => handleRemoveTopic(index)}
                />
              </div>
            </li>
          ))}
        </ul>
      ) : (
        !isEdit && <h3 className="topics-list-empty">There are no topics</h3>
      )}
      {isEdit ? (
        <CourseTopicAddEdit
          topic={editTopicRef.current ? topics[editTopicRef.current] : null}
          onChange={handleAddTopic}
          onCancel={handleSwitchToPreviewMode}
        />
      ) : (
        <Button onClick={() => handleSwitchToEditMode()}>Add topic</Button>
      )}
    </Paper>
  );
};

CourseTopicsList.propTypes = {
  topics: PropTypes.array,
  onChange: PropTypes.func,
};
