import { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Paper, Row, Col, Button, Image } from '../../../UI';
import CourseAddEditForm from '../CourseAddEditForm';
import { wrap, container, pictureContainer, wordWrap } from './styles';
import badgeDefault from '../../../../assets/images/default-badge.jpeg';

const CourseInfoBox = forwardRef((props, ref) => {
  const { data, onCourseInfoChange } = props;
  const [isEdit, setIsEdit] = useState(false);
  const { getText } = useTranslations();

  useImperativeHandle(ref, () => ({
    setIsEdit: () => setIsEdit(!isEdit),
  }));

  return (
    <Paper
      css={wrap}
      header={
        <div css={container}>
          <h2>Course Info</h2>
          {!isEdit && <Button onClick={() => setIsEdit(true)}>Edit</Button>}
        </div>
      }>
      {data && (
        <>
          {isEdit ? (
            <CourseAddEditForm onSubmit={onCourseInfoChange} data={data} />
          ) : (
            <>
              <Image src={data?.picUrl ?? badgeDefault} width={200} height={120} size="cover" css={pictureContainer} />
              <Row md={6} margin="0 0 16px">
                <Col>
                  <h4>Name</h4>
                </Col>
                <Col>
                  <span>{data?.name ? getText(data?.name) : 'N/A'}</span>
                </Col>
              </Row>
              <Row md={6} margin="0 0 16px">
                <Col>
                  <h4>Category</h4>
                </Col>
                <Col>
                  <span>{data?.category?.name ? getText(data?.category?.name) : 'N/A'}</span>
                </Col>
              </Row>
              <Row md={6} margin="0 0 16px">
                <Col>
                  <h4>Intro for listing</h4>
                </Col>
                <Col>
                  <span css={wordWrap}>{data?.introText ? getText(data?.introText) : 'N/A'}</span>
                </Col>
              </Row>
              <Row md={6} margin="0 0 16px">
                <Col>
                  <h4>Summary</h4>
                </Col>
                <Col>
                  <span css={wordWrap}>{data?.summary ? getText(data?.summary) : 'N/A'}</span>
                </Col>
              </Row>
              <Row md={6} margin="0 0 16px">
                <Col>
                  <h4>Description</h4>
                </Col>
                <Col>
                  <span css={wordWrap}>{data?.description ? getText(data?.description) : 'N/A'}</span>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </Paper>
  );
});

CourseInfoBox.propTypes = {
  data: PropTypes.object,
  onCourseInfoChange: PropTypes.func,
};

export default CourseInfoBox;
