import { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { orderBy } from 'lodash-es';
import { Icon, Button, Tooltip, showApiError, showSuccess } from '../../../UI';
import LanguageSelect from '../../../Shared/LanguageSelect';
import LessonAddEditForm from '../LessonAddEditForm';
import LessonsTextAddEditForm from '../LessonsTextAddEditForm';
import LessonsTextTranslationModal from '../LessonsTextTranslationModal';
import uuid from '../../../../utils/uuid';
import { getAllLessons, updateLesson, updateTextLesson } from '../../../../services/lessons';
import {
  wrap,
  noFilesWrap,
  lessonWrap,
  lessonFormWrap,
  pdfWrapper,
  videoWrapper,
  videoInner,
  videoStyle,
  contentWrap,
  loadMoreButton,
} from './styles';

const LessonsList = forwardRef((props, ref) => {
  const { courseId } = useParams();
  const { getText } = useTranslations();
  const [lessons, setLessons] = useState({});
  const [selected, setSelected] = useState(null);

  const pageIndex = useRef(0);
  const translationsTextModalRef = useRef(null);
  const languageSelectRef = useRef(null);

  useEffect(() => {
    fetchAllLessons();
  }, []);

  useImperativeHandle(ref, () => ({
    refreshLessonsList: () => {
      pageIndex.current = 0;
      fetchAllLessons(0, true);
    },
  }));

  const fetchAllLessons = async (pageIndexValue = 0, refresh) => {
    const [res, err] = await getAllLessons({
      pageSize: 25,
      pageIndex: pageIndexValue,
      searchCriteria: 'courseId',
      searchInput: courseId,
    });

    if (err) return showApiError(err);

    const data = orderBy(
      res?.data?.map((el) => ({
        ...el,
        title: el?.courseStep?.title,
        visible: el?.courseStep?.visible,
        type: el?.courseStep?.type,
      })),
      'id',
    );

    setLessons((prev) => ({
      data: prev?.data && !refresh ? [...(prev?.data ?? []), ...data] : data,
      total: res?.total,
    }));
    pageIndex.current += 1;
  };

  const handleSubmitVideo = async (formData, lesson) => {
    const newLessonsData = {
      ...lesson,
      ...formData,
    };

    const [, error] = await updateLesson(lesson?.id, {
      ...newLessonsData,
      courseId,
    });
    if (error) return showApiError(error);
    showSuccess({
      title: 'Edited lesson',
      message: 'Successfully edited lesson.',
    });

    const updatedLesson = lessons?.data?.map((el) => (el.id === lesson.id ? { ...el, ...formData } : el));

    setLessons((prev) => ({ data: updatedLesson, total: prev?.total }));
    setSelected(null);
  };

  const handleSubmitPdf = async (formData, lesson) => {
    const [, error] = await updateTextLesson(lesson?.id, {
      ...lesson,
      ...formData,
      courseId,
    });
    if (error) return showApiError(error);
    showSuccess({
      title: 'Edited lesson',
      message: 'Successfully edited lesson.',
    });

    const updatedLesson = lessons?.data?.map((el) => (el?.id === lesson?.id ? { ...el, ...formData } : el));

    setLessons((prev) => ({ data: updatedLesson, total: prev?.total }));
    setSelected(null);
  };

  const handleLanguageChange = (data) => {
    if (!data) return;

    translationsTextModalRef.current.setIsShow();
    translationsTextModalRef.current.setLanguageData({ ...data, uuid: uuid() });
  };

  const getVideoUrl = (step) => {
    const videoIds = step.sourceUrl.split('vimeo.com/')[1];
    const videoPrimaryId = videoIds.split('/')[0];
    const videoSecondaryId = videoIds.split('/')[1];
    const secondIdParam = videoSecondaryId ? `h=${videoSecondaryId}&amp;` : '';

    return `https://player.vimeo.com/video/${videoPrimaryId}?${secondIdParam}badge=0&amp;autopause=0&amp;player_id=0`;
  };

  return (
    <>
      <div css={wrap}>
        <h3>Uploaded lessons</h3>
        {lessons?.data?.length ? (
          lessons?.data?.map((el) => (
            <div key={el.id}>
              {selected?.id === el.id ? (
                <div css={lessonFormWrap}>
                  <LanguageSelect ref={languageSelectRef} fullData onChange={(data) => handleLanguageChange(data)} />
                  {el?.courseStep?.type !== 'text' ? (
                    <LessonAddEditForm
                      data={el}
                      onCancel={() => setSelected(null)}
                      onSubmit={(data) => handleSubmitVideo(data, el)}
                    />
                  ) : (
                    <LessonsTextAddEditForm
                      data={el}
                      onCancel={() => setSelected(null)}
                      onSubmit={(data) => handleSubmitPdf(data, el)}
                    />
                  )}
                </div>
              ) : (
                <div css={lessonWrap}>
                  {el?.courseStep?.type === 'text' ? (
                    <a
                      href={el?.lessonMaterial ? URL.createObjectURL(el?.lessonNewText) : el?.sourceUrl}
                      rel="noreferrer"
                      target="_blank">
                      <div css={pdfWrapper}>
                        <Icon iconName="fa fa-file-pdf" />
                      </div>
                    </a>
                  ) : (
                    <div css={videoWrapper}>
                      <div css={videoInner}>
                        <iframe
                          src={getVideoUrl(el)}
                          allow="autoplay; fullscreen; picture-in-picture"
                          allowFullScreen
                          css={videoStyle}
                          title={el?.title}
                        />
                      </div>
                    </div>
                  )}
                  <div css={contentWrap}>
                    <h3>{getText(el?.title)}</h3>
                    {el?.courseStep?.type === 'video' && (
                      <p>
                        <strong>Duration:</strong> {el?.duration}
                      </p>
                    )}
                    <div>
                      <Tooltip content={el?.isFree ? 'free' : 'not-free'}>
                        <Icon
                          material
                          color={el?.isFree ? 'gray' : 'green'}
                          iconName={el?.isFree ? 'money_off' : 'attach_money'}
                        />
                      </Tooltip>
                      <Tooltip content={el?.visible ? 'visible' : 'not-visible'}>
                        <Icon
                          material
                          color={el?.visible ? 'info' : 'gray'}
                          iconName={el?.visible ? 'visibility' : 'visibility_off'}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <Button onClick={() => setSelected(el)}>Edit</Button>
                </div>
              )}
            </div>
          ))
        ) : (
          <div css={noFilesWrap}>
            <p>No Lessons added!</p>
          </div>
        )}
        {lessons?.data?.length < lessons?.total && (
          <div css={loadMoreButton}>
            <Button info onClick={() => fetchAllLessons(pageIndex.current)}>
              Load more
            </Button>
          </div>
        )}
      </div>
      <LessonsTextTranslationModal
        lessonId={selected?.id}
        ref={translationsTextModalRef}
        onSubmit={() => languageSelectRef.current.resetSelect()}
      />
    </>
  );
});

export default LessonsList;
