import PropTypes from 'prop-types';
import { Form, Button, FormInput } from 'components/UI';
import { topicAddEditContainer } from './styles';

export const CourseTopicAddEdit = (props) => {
  const { topic, onChange, onCancel } = props;

  const handleSubmit = (data) => onChange(data?.topic);

  const SubmitBarTopicAddEdit = (submitProps) => (
    <>
      <Button className="topic-button-submit" onClick={submitProps.onSubmit}>
        Save
      </Button>
      <Button secondary onClick={onCancel}>
        Cancel
      </Button>
    </>
  );

  return (
    <Form
      css={topicAddEditContainer}
      onSubmit={handleSubmit}
      renderSubmit={SubmitBarTopicAddEdit}
      values={topic ? { topic } : {}}>
      <FormInput id="topic" label="Topic (Strank key)" required />
    </Form>
  );
};

CourseTopicAddEdit.propTypes = {
  topic: PropTypes.object,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
};
