import { forwardRef } from 'react';
import { isString } from 'lodash-es';
import PropTypes from 'prop-types';
import { Input } from '../../UI';
import { useFormInput } from '../../UI/Form/useFormInput';
import errorIcon from '../../../assets/images/icn-alert-warning-input.svg';
import successIcon from '../../../assets/images/icn-sm-checkmark-tick.svg';

import {
  inputContainer,
  inputField,
  inputLabel,
  inputHelpContainer,
  inputHelpIcon,
  videoPickerContainer,
} from './styles';

const VideoInput = forwardRef((props, inputRef) => {
  const { id, label, value, className, type, required, horizontal, placeholder, successLabel = 'Field' } = props;

  const {
    value: stateValue,
    onChange,
    onBlur,
    disabled,
    error,
    isTouched,
  } = useFormInput({ ...props, value, inputRef });

  const isCorrect = isTouched && !error;
  const hesError = isTouched && error;

  const handleChange = (event) => onChange({ target: { value: event?.target?.value ?? event } });

  return (
    <div css={inputContainer(horizontal)} {...(isString(className) && { className })}>
      {label && (
        <label htmlFor={id} css={inputLabel(horizontal)}>
          {`${label}${required ? ' *' : ''}`}
        </label>
      )}
      <div css={videoPickerContainer}>
        <Input
          type={type}
          placeholder={isString(placeholder) ? placeholder : `${label}...`}
          onChange={handleChange}
          onBlur={onBlur}
          value={stateValue}
          disabled={disabled}
          css={inputField(!!hesError, isCorrect)}
        />
      </div>
      {(hesError || isCorrect) && (
        <div css={inputHelpContainer(!!error)}>
          <div css={inputHelpIcon(!!error)}>
            <img src={error ? errorIcon : successIcon} alt="Logo" />
          </div>
          <span>{error ? error.msg : `${label || successLabel} is correct`}</span>
        </div>
      )}
    </div>
  );
});

VideoInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  horizontal: PropTypes.bool,
  successLabel: PropTypes.string,
  className: PropTypes.string,
};

export default VideoInput;
