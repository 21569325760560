import ColorBatch from '../../../components/Shared/ColorBatch';
import { DetailsLink } from '../../../components/Shared/TableComponents';

export const courseStatus = {
  draft: {
    text: 'Draft',
    color: 'warning',
  },
  active: {
    text: 'Active',
    color: 'success',
  },
  inactive: {
    text: 'Inactive',
    color: 'error',
  },
};

export const courseStepTypes = {
  quiz: {
    text: 'Quiz',
    color: 'warning',
  },
  video: {
    text: 'Video',
    color: 'orange',
  },
};

export const columns = (getText) => [
  {
    name: 'Course id',
    value: 'id',
    sortable: true,
    width: 100,
  },
  {
    name: 'Name',
    render: (course) => getText(course?.name),
  },
  {
    name: 'Status',
    render: (row) => <ColorBatch type={courseStatus[row.status]?.color}>{courseStatus[row.status]?.text}</ColorBatch>,
    width: 150,
  },
  {
    name: 'Category',
    render: (row) => getText(row?.category?.name),
    width: 200,
  },
  {
    name: 'View details',
    render: (row) => <DetailsLink row={row} url={`/courses/${row.id}`} />,
    width: 100,
  },
];

export const pages = (courseId) => [
  { url: '/courses', label: 'Courses' },
  {
    label: courseId ? 'Course Details' : 'Add Course',
  },
];

export const pagesOrder = [
  { url: '/courses', label: 'Courses' },
  {
    label: 'Order Courses',
  },
];

export const pagesOrderSteps = (courseId) => [
  { url: '/courses', label: 'Courses' },
  { url: `/courses/${courseId}`, label: 'Course Details' },
  {
    label: 'Order Courses Steps',
  },
];
