import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormInput, FormDropdown, showApiError } from '../../../UI';
import ColorBatch from '../../../Shared/ColorBatch';
import UploadImage from '../../../Shared/UploadImage';
import { getAllCategories } from '../../../../services/categories';
import { packagesContainer } from './styles';
import badge from '../../../../assets/images/default-badge.jpeg';

const CourseAddEditForm = (props) => {
  const { data, packages, onSubmit } = props;
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const [res, err] = await getAllCategories();
    if (err) return showApiError(err);

    setCategories(res?.data ?? []);
  };

  const packagesListOptions = packages?.map((el) => ({
    ...el,
    render: () => (
      <div css={packagesContainer}>
        <div>
          <strong>{el.catalogueItemId}</strong>
          <span>{el.name}</span>
        </div>
        <div>
          <ColorBatch type={el.active ? 'success' : 'error'}>{el.active ? 'Active' : 'Inactive'}</ColorBatch>
          <ColorBatch type={el.visible ? 'success' : 'error'}>{el.visible ? 'Visible' : 'Invisible'}</ColorBatch>
        </div>
      </div>
    ),
  }));

  const categoriesListOptions = categories?.map((el) => ({
    ...el,
    render: () => (
      <div css={packagesContainer}>
        <div>
          <span>{el.name}</span>
        </div>
        <div>
          <ColorBatch type={el.visible ? 'success' : 'error'}>{el.visible ? 'Visible' : 'Invisible'}</ColorBatch>
        </div>
      </div>
    ),
  }));

  return (
    <Form
      onSubmit={onSubmit}
      submitButton={{
        children: data ? 'Save' : 'Add Course',
      }}
      values={data ?? {}}>
      <UploadImage id="picUrl" defaultValue={badge} />
      <FormInput id="name" label="Name (Strank key)" required />
      <FormDropdown
        required
        noClear
        withSearch
        id="category"
        label="Category (Strank key)"
        placeholder="Select category..."
        displayKey="name"
        uniqueKey="id"
        options={categoriesListOptions}
      />
      {!data && (
        <FormDropdown
          required
          noClear
          withSearch
          multiSelect
          id="catalogueItems"
          label="Packages"
          placeholder="Select packages..."
          displayKey="name"
          uniqueKey="catalogueItemId"
          options={packagesListOptions}
        />
      )}
      <FormInput id="introText" label="Introduction (Strank key)" placeholder="Introduction (visible on All Courses)" />
      <FormInput id="summary" label="Summary (Strank key)" placeholder="Summary" />
      <FormInput id="description" label="Description (Strank key)" placeholder="Description" />
    </Form>
  );
};

CourseAddEditForm.propTypes = {
  data: PropTypes.object,
  packages: PropTypes.any,
  onSubmit: PropTypes.func,
};

export default CourseAddEditForm;
