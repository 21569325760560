import { pick } from 'lodash-es';
import queryString from 'query-string';

export const getAllCategoriesParams = (options) => {
  const defaultSort = {
    sortBy: 'id',
    sortDirection: 'desc',
  };

  const mapSort = (sort) => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  const filterFields = [];
  const filterValues = [];

  if (options.searchCriteria && options.searchInput?.length) {
    filterFields.push(options.searchCriteria);
    filterValues.push(options.searchInput);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};
